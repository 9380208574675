import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'ngx-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  @AutoUnsubscribe()
  private isLoggedInSubs: Subscription;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  constructor(public authService: AuthService, private router: Router) {
    this.isLoggedInSubs = this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.router.navigateByUrl('/users');
        }
      }
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    //this.authService.hashPassword(this.form.value.password!, this.form.value.email!).then((hash) => {
    //const userSignInRequest = { ...this.form.value, password: hash };
    this.authService.userLoginRequest(this.form.value).subscribe((response) => {
      if (response.success && response.data) {
        this.authService.successAuthorize(response.data);
      }
    });
    //});
  }
}
