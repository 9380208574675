<div class="d-flex flex-column align-items-center">
  <img src="../../../assets/images/gaimin-logo.svg" alt="" class="logo" />
  <nb-card>
    <nb-card-body>
      <form action="" class="d-flex flex-column login" [formGroup]="form" (ngSubmit)="onSubmit()">
        <input nbInput fullWidth class="login__input" type="text" placeholder="Login" formControlName="email" />
        <div class="login__error" *ngIf="form.get('email')?.invalid && form.get('email')?.touched">
          <div *ngIf="form.get('email')?.errors?.['required']">Email should be present.</div>
        </div>
        <input
          nbInput
          fullWidth
          class="login__input"
          type="password"
          placeholder="Password"
          formControlName="password" />
        <div class="login__error" *ngIf="form.get('password')?.invalid && form.get('password')?.touched">
          <div *ngIf="form.get('password')?.errors?.['required']">Password should be present.</div>
        </div>
        <button nbButton status="primary" outline class="login__btn" type="submit">Login</button>
      </form>
    </nb-card-body>
  </nb-card>
</div>
<app-loader></app-loader>
