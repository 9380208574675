import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;

  constructor(private toastrService: NbToastrService, public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse && request.url.includes(environment.authModuleApi)) {
          if (!(evt.body instanceof Blob) && !evt?.body.success) {
            if (evt?.body.error.type == 'AUTHENTICATION') {
              if (request.url?.includes(environment.authModuleApi + '/users/auth/refresh')) {
                this.authService.logout();
              } else if (request.url.includes(environment.authModuleApi)) {
                this.handleAuthModuleToken();
              } else {
                this.authService.logout();
              }
            } else {
              this.toastrService.danger(
                `Error: ${evt?.body.error.type}`,
                `Something went wrong! ${evt?.body.error.description}`
              );
            }
          }
        }
      }),
      catchError((error): any => {
        console.log('Error:', error);
        this.toastrService.danger(`Error: ${error?.error.error}`, `Something went wrong!`);
      })
    );
  }

  private handleAuthModuleToken() {
    if (this.isRefreshing) {
      return;
    }
    this.isRefreshing = true;
    const refreshToken = this.authService.adminCredential.refreshToken;
    if (refreshToken) {
      return this.authService
        .refreshTokenRequest()
        .pipe(
          tap((response) => {
            this.isRefreshing = false;
            if (response.data) {
              this.authService.successAuthorize(response.data);
              this.authService.adminCredential.accessToken = response.data!.accessToken;
              this.authService.adminCredential.refreshToken = response.data!.refreshToken;
              window.location.reload();
            } else {
              this.authService.logout();
            }
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService.logout();
            return throwError(() => error);
          })
        )
        .subscribe();
    } else {
      console.log('Refresh token doesn`t exist');
      this.authService.logout();
      return;
    }
  }
}
