import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { UserService } from '../../../shared/services/user.service';
import { AdditionalService } from '../../../shared/services/additional.service';
import { AuthService } from '../../../shared/services/auth.service';
import { PaginationService } from '../../../shared/services/pagination.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { PaginationInstance, UserData, UsersDataFilter } from '../../../shared/interfaces';

@Component({
  selector: 'ngx-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.scss']
})
export class UsersListPageComponent implements OnInit {
  @AutoUnsubscribe()
  private tableDataSubs: Subscription | undefined;
  tableData: UserData[] | undefined;

  params = { page: 1, size: 20 };
  filterData: UsersDataFilter = {};
  paginationConfig: PaginationInstance = { currentPage: 1, itemsPerPage: 20, totalItems: 0 };

  initialFormValues: UsersDataFilter = {
    name: ''
  };

  form: FormGroup = new FormGroup({
    name: new FormControl(this.initialFormValues.name, [Validators.required])
  });

  constructor(
    public additionalService: AdditionalService,
    public userService: UserService,
    public authService: AuthService,
    public paginationService: PaginationService,
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.loadTableData(this.params);
  }

  pageChanged(page: number) {
    this.paginationConfig.currentPage = page;
    this.params = { page: page, size: this.paginationConfig.itemsPerPage };
    this.loadTableData({ ...this.params, ...this.filterData });
  }

  onFilter() {
    this.filterData = {};
    this.params.page = 1;
    this.paginationConfig.currentPage = 1;

    const { value } = this.form;

    this.filterData = {
      ...(value.name.trim() !== '' && { name: value.name.trim() })
    };

    this.loadTableData({ ...this.params, ...this.filterData });
  }

  onClear() {
    this.form.patchValue(this.initialFormValues);
    this.onFilter();
  }

  loadTableData(params: {}) {
    this.tableDataSubs = this.userService.getUsersRequest(params).subscribe((res) => {
      if (res?.success && res.data) {
        this.tableData = res.data.users || [];
        this.paginationConfig.totalItems = res.data.paginationDto.totalResults;
      }
    });
  }
}
