import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ClipboardService } from 'ngx-clipboard';

@Injectable({
  providedIn: 'root'
})
export class AdditionalService {
  constructor(private toastrService: NbToastrService, private clipboardService: ClipboardService) {}

  allowOnlyNumbers(event: any) {
    const allowedRegex = /\d/g;
    if (!allowedRegex.test(event.key)) {
      event.preventDefault();
    }
  }

  fieldsMatchValidator(controlName1: string, controlName2: string, shouldMatch: boolean): ValidatorFn {
    return (group: AbstractControl): { [key: string]: boolean } | null => {
      const control1 = group.get(controlName1)?.value;
      const control2 = group.get(controlName2)?.value;

      const fieldsMatch = control1 && control2 && control1 === control2;

      if ((shouldMatch && !fieldsMatch) || (!shouldMatch && fieldsMatch)) {
        return { fieldsMatch: true };
      }

      return null;
    };
  }

  public copyToClipboard(text: string) {
    this.clipboardService.copy(text);
    this.toastrService.success('', 'Copied to clipboard!');
  }
}
