import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Response, UserSignInRequest, AuthTokens } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserRoleSrc = new BehaviorSubject<string | null>(null);
  public currentUserRole$ = this.currentUserRoleSrc.asObservable();
  private isLoggedInSrc = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this.isLoggedInSrc.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    if (this.adminCredential.accessToken && this.adminCredential.role) {
      this.login();
    } else {
      this.logout();
    }
  }

  get adminCredential() {
    const adminCredential = localStorage.getItem('adminCredential');

    if (adminCredential) {
      try {
        return JSON.parse(adminCredential);
      } catch (error) {
        return { accessToken: null, refreshToken: null, role: null };
      }
    }
    return { accessToken: null, refreshToken: null, role: null };
  }

  set adminCredential(data: { accessToken: string | null; refreshToken: string | null; role: string | null }) {
    if (data) {
      localStorage.setItem('adminCredential', JSON.stringify(data));
    } else {
      localStorage.removeItem('adminCredential');
    }
  }

  login() {
    this.isLoggedInSrc.next(true);
    this.currentUserRoleSrc.next(this.adminCredential.role);
  }

  logout() {
    this.adminCredential = { accessToken: null, refreshToken: null, role: null };
    this.isLoggedInSrc.next(false);
    this.currentUserRoleSrc.next(null);
    this.router.navigateByUrl('/');
  }

  checkUserRights(userRole: string) {
    return userRole === this.currentUserRoleSrc.getValue();
  }

  async hashPassword(password: string, salt: string): Promise<string> {
    const encoder = new TextEncoder();
    const passwordKey = encoder.encode(password);
    const saltKey = encoder.encode(salt);

    // Import the password as a CryptoKey object
    const key = await crypto.subtle.importKey('raw', passwordKey, { name: 'PBKDF2' }, false, ['deriveBits']);

    // Derive the key using PBKDF2
    const derivedBits = await crypto.subtle.deriveBits(
      {
        name: 'PBKDF2',
        salt: saltKey,
        iterations: 100000, // number of iterations
        hash: 'SHA-256'
      },
      key,
      256 // result hash length in bits
    );

    // Convert the derived bits to a hex string
    const hashArray = Array.from(new Uint8Array(derivedBits));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  successAuthorize(data: AuthTokens) {
    this.adminCredential = data;
    this.login();
  }

  userLoginRequest(userData: UserSignInRequest): Observable<Response<AuthTokens>> {
    return this.http.post<Response<AuthTokens>>(`${environment.authModuleApi}/auth/token/password`, userData);
  }

  refreshTokenRequest(): Observable<Response<AuthTokens>> {
    const refreshToken: string = this.adminCredential.refreshToken || '';
    console.log('Make refresh token request...', this.adminCredential.refreshToken);
    return this.http.post<Response<AuthTokens>>(`${environment.authModuleApi}/auth/token/refresh`, { refreshToken });
  }
}
